import { gql } from '@apollo/client';

export const ACTIVITY_FRAGMENT = gql`
  fragment ActivityFields on Activity {
    activityRef
    activitySetRef
    activityTypeRef
    start: startAt
    end: endAt
    updatedAt
    jobScheduleGroupId
  }
`;

export const ACTIVITY_SET_FRAGMENT = gql`
  fragment ActivitySetFields on ActivitySet {
    activitySetRef
    companyId
    machineRef
    closedAt
    updatedAt
    expectedSetupDuration
    idealUnitDuration
    expectedUnitDuration
    jobId
    partCountMultiplier
    actualParts
    workOrderId
    workOrderOperationRef
  }
`;
