import React from 'react';
import { getIsERPJobControlBarFlow } from 'lib/selectors/getIsERPJobControlBarFlow';
import { useSelector } from 'react-redux';
import { ErpOperatorPill } from './ErpOperatorPill';
import { StandardOperatorPill } from './StandardOperatorPill';

const OperatorPill = () => {
  const showERPJobControlBar = useSelector(getIsERPJobControlBarFlow);

  const Pill = showERPJobControlBar ? ErpOperatorPill : StandardOperatorPill;

  return <Pill />;
};

export { OperatorPill };
