import { createSelector } from 'reselect';
import { FLAG_KEYS } from 'lib/constants';
import { getMachine } from 'lib/selectors/getMachine';
import { getLaunchDarklyFlags } from 'lib/selectors/getLaunchDarklyFlags';
import _get from 'lodash/get';

const getIsERPJobControlBarFlow = createSelector(
  [getLaunchDarklyFlags, getMachine],
  (flags, machine) => {
    const erpResourceId = _get(machine, 'erpResourceId');
    const erpAssociatedResources = _get(machine, 'erpResources');
    const laborTicketsEnabled = flags[FLAG_KEYS.LABOR_TICKET_ENABLED];

    return (
      laborTicketsEnabled && !!(erpResourceId || erpAssociatedResources?.length)
    );
  }
);

export { getIsERPJobControlBarFlow };
