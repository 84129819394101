import React from 'react';
import { Pill, palette, Icon } from '@m12s/component-library';

import { user } from 'lib/icons';

import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { useSelector } from 'react-redux';
import { PillWrapper, PillText } from './styled';

const ErpOperatorPill = () => {
  const operator = useSelector(getCurrentOperator);

  return !operator ? null : (
    <PillWrapper id="operator-pill">
      <Pill
        backgroundColor={palette.White}
        borderColor={palette.Grey400}
        textColor={palette.Grey700}
      >
        <Icon icon={user} size="1rem" />
        <PillText>{operator?.name}</PillText>
      </Pill>
    </PillWrapper>
  );
};

export { ErpOperatorPill };
