import { createSelector } from 'reselect';
import { FLAG_KEYS } from 'lib/constants';
import { getWorkOrderOpTotalParts } from './getWorkOrderOpTotalParts';
import { getLaborScopeTotalParts } from './getScopeTotalParts';
import { getLaunchDarklyFlags } from './getLaunchDarklyFlags';

export const getOrderProgress = createSelector(
  [getWorkOrderOpTotalParts, getLaborScopeTotalParts, getLaunchDarklyFlags],
  (workOrderOpTotalParts, laborScopeTotalParts, flags) => {
    if (flags[FLAG_KEYS.LABOR_TICKET_ENABLED]) {
      return workOrderOpTotalParts + laborScopeTotalParts;
    }
    return workOrderOpTotalParts;
  }
);
