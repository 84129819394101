import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  CounterBlock,
  Wheel,
  WheelLabel,
  WheelSublabel,
} from '@m12s/component-library';
import { check, arrowUp, arrowDown } from 'lib/icons';
import { PartsMadeChart, CycleTimeChart } from 'components/charts';
import { PartsRequiredMeter } from 'components/PartsRequiredMeter';
import {
  WheelLayout,
  ChartLabel,
  CounterLabel,
  WheelCounterPadding,
  WheelChartPadding,
} from 'components/WheelLayout';
import { OperatorPill } from 'components/OperatorPill';
import {
  PauseDetailsToggle,
  PauseDetailsOverlayContainer,
} from 'components/PauseDetails';
import { LoadingTextPlaceholder } from 'components/LoadingTextPlaceholder';
import { Loading } from 'components/Loading';
import { TextMedia } from 'pages/Machine/styled';
import NoGoal from 'graphics/NoGoal';
import {
  NoJobIcon,
  PartCountPerfStatusIcon,
  Faded,
  ExpectedItems,
} from 'components/TabsCommon';
import MachineWorkOrderSection from 'components/MachineWorkOrderSection';

import { useIsWorkOrderFlow } from 'lib/hooks/useIsWorkOrderFlow';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { formatValue } from 'lib/utils/text';
import { getIsOperatorTrackingEnabled } from 'lib/selectors/getIsOperatorTrackingEnabled';
import { getPartsMadeForJob } from 'lib/selectors/getPartsMadeForJob';
import { getRejectedParts } from 'lib/selectors/getRejectedParts';
import { getScopeNetPartsMade } from 'lib/selectors/getScopeNetPartsMade';
import { getScopePartsGoal } from 'lib/selectors/getScopePartsGoal';
import { getLatestJob } from 'lib/selectors/getLatestJob';
import { getHasOpenActivity } from 'lib/selectors/getHasOpenActivity';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { getArePartCountBucketsLoading } from 'lib/selectors/getPartCountBuckets';
import { getOpenActivitySetHasNoStandards } from 'lib/selectors/getOpenActivitySetHasNoStandards';
import { getShouldUseOpRunOverrideScope } from 'lib/selectors/getShouldUseOpRunOverrideScope';

const LoadingCounterValue = styled(LoadingTextPlaceholder)`
  height: 2rem;
  width: 90%;
  border-radius: 2px;
`;

const WheelContentStandard = () => {
  const { t } = useTranslation();
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const isPaused = useSelector(getIsPaused);
  const rejectCount = useSelector(getRejectedParts);
  const { isBehind, partsDiff } = useSelector(getScopePartsGoal);

  if (arePartCountBucketsLoading) {
    return <Loading size="32px" fontSize="1rem" text={t('loading')} />;
  }

  return (
    <>
      {hasOpenActivity ? (
        <>
          {openActivitySetHasNoStandards ? (
            <>
              <WheelLabel>
                <NoGoal width="3rem" style={{ marginBottom: '0.25rem' }} />
              </WheelLabel>
              <WheelSublabel>{t('No Operation Standards')}</WheelSublabel>
            </>
          ) : (
            <>
              <WheelLabel>
                {formatValue(Math.abs(partsDiff))?.toLocaleString()}
              </WheelLabel>
              <WheelSublabel>
                {_startCase(isBehind ? t('parts behind') : t('parts ahead'))}
              </WheelSublabel>
            </>
          )}
        </>
      ) : (
        <>
          <WheelLabel>
            <NoJobIcon />
          </WheelLabel>
          <WheelSublabel>
            <Faded>
              {_startCase(
                isPaused ? t('operation paused') : t('no operation running')
              )}
            </Faded>
          </WheelSublabel>
        </>
      )}
      <WheelLabel secondary>{rejectCount || '-'}</WheelLabel>
      <WheelSublabel>{t('rejects')}</WheelSublabel>
    </>
  );
};

const OpRunOverrideRejectLabel = () => {
  const { t } = useTranslation();
  const rejectCount = useSelector(getRejectedParts);
  const labelString = `{{rejectCount}} Reject${rejectCount === 1 ? '' : 's'}`;
  return (
    <WheelSublabel
      style={{
        marginTop: '0.5rem',
      }}
    >
      {t(labelString, {
        rejectCount,
      })}
    </WheelSublabel>
  );
};

const WheelContentOpRunOverride = ({ appearance, fillMode }) => {
  const { t } = useTranslation();
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);
  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );
  const hasOpenActivity = useSelector(getHasOpenActivity);
  const isPaused = useSelector(getIsPaused);
  const { isAtGoal, isBehind, partsDiff } = useSelector(getScopePartsGoal);
  const absPartCount = Math.abs(partsDiff)?.toLocaleString();
  const statusIcon = useMemo(() => {
    if (isAtGoal) {
      return check;
    }
    if (isBehind) {
      return arrowDown;
    }
    return arrowUp;
  }, [isAtGoal, isBehind]);
  if (arePartCountBucketsLoading) {
    return <Loading size="32px" fontSize="1rem" text={t('Loading')} />;
  }
  if (hasOpenActivity) {
    if (openActivitySetHasNoStandards) {
      return (
        <>
          <WheelLabel>
            <NoGoal width="3rem" style={{ marginBottom: '0.25rem' }} />
          </WheelLabel>
          <WheelLabel secondary>
            <Faded style={{ fontSize: '1.4rem' }}>
              {t('No Operation Standards')}
            </Faded>
          </WheelLabel>
          <OpRunOverrideRejectLabel />
        </>
      );
    }
    return (
      <>
        <WheelLabel>
          <PartCountPerfStatusIcon
            icon={statusIcon}
            appearance={appearance}
            fillMode={fillMode}
          />
        </WheelLabel>
        {isAtGoal ? (
          <WheelLabel secondary>{t('At Goal')}</WheelLabel>
        ) : (
          <WheelLabel secondary>
            {t(`{{absPartCount}} ${isBehind ? 'Behind' : 'Ahead'}`, {
              absPartCount,
            })}
          </WheelLabel>
        )}
        <OpRunOverrideRejectLabel />
      </>
    );
  }
  return (
    <>
      <WheelLabel>
        <NoJobIcon
          style={{
            marginBottom: '0',
            fontSize: '2.75rem',
          }}
        />
      </WheelLabel>
      <WheelLabel secondary>
        <Faded style={{ fontSize: '1.4rem' }}>
          {isPaused ? t('Operation Paused') : t('No Operation Running')}
        </Faded>
      </WheelLabel>
      <OpRunOverrideRejectLabel />
    </>
  );
};

const PartCount = () => {
  const { t } = useTranslation();
  const workOrderFlowEnabled = useIsWorkOrderFlow();
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const showWorkOrderSections = workOrderFlowEnabled && currentWorkOrderOp;

  const isOperatorTrackingEnabled = useSelector(getIsOperatorTrackingEnabled);
  const partsMadeForJob = useSelector(getPartsMadeForJob);

  const scopeNetPartsMade = useSelector(getScopeNetPartsMade);
  const shouldUseOpRunOverrideScope = useSelector(
    getShouldUseOpRunOverrideScope
  );
  const {
    expectedPartsNow,
    totalExpectedParts,
    partsMadePercent,
  } = useSelector(getScopePartsGoal);

  const openActivitySetHasNoStandards = useSelector(
    getOpenActivitySetHasNoStandards
  );

  const calculateValue = (item, getItemStyle) => {
    return {
      value: [item.time, item.sum],
      itemStyle: getItemStyle(item),
    };
  };

  const hasOpenActivity = useSelector(getHasOpenActivity);
  const {
    failurePercent,
    warningPercent,
    quantity: partsRequired,
  } = useSelector(getLatestJob);
  const arePartCountBucketsLoading = useSelector(getArePartCountBucketsLoading);

  const appearance = useMemo(() => {
    switch (true) {
      case !hasOpenActivity:
        return 'empty';
      case openActivitySetHasNoStandards:
        return 'noStandards';
      case (!expectedPartsNow && !partsMadePercent) ||
        arePartCountBucketsLoading:
        return 'empty';
      case partsMadePercent < failurePercent:
        return 'failure';
      case partsMadePercent < warningPercent:
        return 'warning';
      case partsMadePercent >= warningPercent:
        return 'positive';
      default:
        return 'empty';
    }
  }, [
    hasOpenActivity,
    expectedPartsNow,
    partsMadePercent,
    failurePercent,
    warningPercent,
    arePartCountBucketsLoading,
    openActivitySetHasNoStandards,
  ]);

  const currentWorkOrderHasStandards =
    !openActivitySetHasNoStandards || currentWorkOrderOp?.cycleTimeMs;

  return (
    <PauseDetailsOverlayContainer>
      <WheelLayout
        id="part-count-page"
        topLeft={
          <WheelCounterPadding top left>
            <CounterBlock
              label={
                <CounterLabel loading={arePartCountBucketsLoading}>
                  <TextMedia min={40}>
                    {_startCase(t('you have made'))}
                  </TextMedia>
                  <TextMedia max={40}>{_startCase(t('current'))}</TextMedia>
                </CounterLabel>
              }
              value={
                arePartCountBucketsLoading ? (
                  <LoadingCounterValue />
                ) : (
                  formatValue(scopeNetPartsMade)?.toLocaleString()
                )
              }
            />
          </WheelCounterPadding>
        }
        topRight={<ExpectedItems value={expectedPartsNow} />}
        bottomRight={
          showWorkOrderSections && currentWorkOrderHasStandards ? (
            <MachineWorkOrderSection.BottomRight />
          ) : (
            <WheelChartPadding>
              <ChartLabel
                tooltip={t(
                  'This area displays the number of Parts that have been produced by this Machine at specific times during the current shift.'
                )}
                loading={arePartCountBucketsLoading}
              >
                {_startCase(t('parts made'))}
              </ChartLabel>
              <PartsMadeChart calculateValue={calculateValue} />
            </WheelChartPadding>
          )
        }
        bottomLeft={
          showWorkOrderSections ? (
            <MachineWorkOrderSection.BottomLeft />
          ) : partsRequired && hasOpenActivity ? (
            <WheelChartPadding>
              <PartsRequiredMeter
                partsMadeForJob={partsMadeForJob}
                partsRequired={partsRequired}
              />
            </WheelChartPadding>
          ) : (
            <WheelChartPadding>
              <ChartLabel
                tooltip={t(
                  'This area displays Machine Cycle Time information for the current shift. For more information, visit the Current Cycle Time tab and select "Learn more about this Metric".'
                )}
                tooltipDirection="top"
                loading={arePartCountBucketsLoading}
              >
                {_startCase(t('cycle time'))}
              </ChartLabel>
              <CycleTimeChart micro />
            </WheelChartPadding>
          )
        }
        wheel={
          <div>
            <Wheel
              goal={
                (totalExpectedParts && expectedPartsNow / totalExpectedParts) *
                100
              }
              shape="rounded"
              value={
                totalExpectedParts &&
                (scopeNetPartsMade / totalExpectedParts) * 100
              }
              appearance={appearance}
              fillMode="full"
              showProgress={!shouldUseOpRunOverrideScope}
              withMarkers={!shouldUseOpRunOverrideScope}
              Contents={
                shouldUseOpRunOverrideScope ? (
                  <WheelContentOpRunOverride
                    appearance={appearance}
                    fillMode="full"
                  />
                ) : (
                  <WheelContentStandard />
                )
              }
            />
          </div>
        }
        login={isOperatorTrackingEnabled && <OperatorPill />}
      />
      <PauseDetailsToggle />
    </PauseDetailsOverlayContainer>
  );
};

export { PartCount };
