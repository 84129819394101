// React and Hooks
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Actions
import { actionSetOperators } from 'lib/actions';

// Selectors
import {
  getActiveOperators,
  getHasUnmappedActiveOperators,
} from 'lib/selectors/getActiveOperators';

// Utils
import { GET_OPERATORS } from 'lib/api/queries';
import PropTypes from 'prop-types';

// Components
import { AppNav } from 'components/AppNav';
import { SignInModal } from 'components/SignInModal';
import { EmptyWithGraphic } from 'components/Empty/EmptyWithGraphic';
import NoOperators from 'graphics/NoOperators';
import { Root, GridWrapper, InputWrapper } from 'components/UserSelect/styled';
import { H5 } from 'components/Text';
import { palette, CardGrid, Card, Icon } from '@m12s/component-library';
import Downshift from 'downshift';
import { SearchInput } from 'components/SearchInput';
import { chevronRight, questionCircle } from 'lib/icons';
import { DefaultLoading } from 'components/Loading';
import { InlineAlert } from 'components/InlineAlert/InlineAlert';
import styled from 'styled-components';
import { DefaultCardBorder } from 'components/MachineHeader/styled';

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
    case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.touchEnd:
    case Downshift.stateChangeTypes.clickItem:
      return state;
    default:
      return changes;
  }
}

// This component will be removed in a future PR, so I create this dependency here to go way with it
const CardGridItem = styled(Card)`
  ${DefaultCardBorder}
`;

const HeaderText = styled(H5)`
  width: 100%;
  text-align: left;
  padding-left: 1.5rem;
  font-weight: 500;
  margin-left: -8rem;
`;

const ForceLogin = ({ onSubmit }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const activeOperators = useSelector(getActiveOperators);
  const hasUnmappedActiveOperators = useSelector(getHasUnmappedActiveOperators);
  const dispatch = useDispatch();

  const [selectedOperator, setSelectedOperator] = useState(null);
  const hasOperators = activeOperators?.length;

  // get a fresh list of operators
  const { loading } = useQuery(GET_OPERATORS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ Operator: operators }) => {
      dispatch(actionSetOperators(operators));
    },
  });

  if (loading) return <DefaultLoading />;

  return (
    <Root id="force-operator-login-modal">
      <SignInModal
        isOpen={!!selectedOperator}
        operator={selectedOperator}
        onClose={() => {
          return history.goBack();
        }}
        onSubmit={(...args) => {
          return onSubmit(...args);
        }}
      />
      <AppNav
        iconColor={palette.Grey700}
        color={palette.White}
        onClose={() => {
          return history.goBack();
        }}
      >
        <HeaderText>{t('Select Operator')}</HeaderText>
      </AppNav>
      <Downshift
        isOpen
        itemToString={(item) => {
          return item?.name || '';
        }}
        stateReducer={stateReducer}
        selectedItem={selectedOperator}
      >
        {({ getRootProps, getInputProps, inputValue, getMenuProps }) => {
          const filteredOperators = activeOperators.filter((operator) => {
            return operator.name
              .toLowerCase()
              .includes(inputValue.toLowerCase());
          });
          const hasFilteredOperators = !!filteredOperators?.length;
          return (
            <>
              <InputWrapper hidden={!hasOperators}>
                <SearchInput
                  rootProps={getRootProps({}, { suppressRefError: true })}
                  inputProps={getInputProps({
                    onBlur: (fn) => {
                      return fn;
                    },
                  })}
                />
              </InputWrapper>
              {hasUnmappedActiveOperators && (
                <InlineAlert text={t('operatorUnlistedOnERPCopy')} />
              )}
              {hasFilteredOperators ? (
                <GridWrapper>
                  <CardGrid {...getMenuProps()}>
                    {filteredOperators.map((operator) => {
                      return (
                        <CardGridItem
                          key={operator.id}
                          color={null}
                          type="pill"
                          icon={<Icon icon={chevronRight} />}
                          onClick={() => {
                            return setSelectedOperator(operator);
                          }}
                        >
                          {operator.name}
                        </CardGridItem>
                      );
                    })}
                  </CardGrid>
                </GridWrapper>
              ) : (
                <EmptyWithGraphic
                  Graphic={<NoOperators margin="1rem" />}
                  icon={questionCircle}
                  text={
                    hasOperators ? t('No Results Found') : t('No Operators')
                  }
                  subtext={t(
                    'Operator accounts must be mapped to your ERP - please contact your administrator for assistance.'
                  )}
                />
              )}
            </>
          );
        }}
      </Downshift>
    </Root>
  );
};

ForceLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export { ForceLogin };
