import React from 'react';
import _startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getLatestSetupActivity } from 'lib/selectors/getLatestSetupActivity';
import { getUnitDurationFromString } from 'lib/utils/date';
import { getAdjustedSetupDurationDates } from 'lib/selectors/getAdjustedSetupDurationDates';
import { getIsPaused } from 'lib/selectors/getIsPaused';
import { WheelLabel, WheelSublabel } from '@m12s/component-library';
import { DurationTicker } from 'components/DurationTicker';
import { OpRunOverrideRejectLabel } from './OpRunOverrideRejectLabel';
import { PausedWheelContent } from './PausedWheelContent';

const setupFormat = {
  formatOptions: {
    largest: 2,
    trim: false,
  },
  format: 'd[d] h[h] m[m] ss[s]',
};

export const WheelContentOpRunOverride = ({ isOvertime }) => {
  const { t } = useTranslation();
  const latestActivitySet = useSelector(getLatestActivitySet);
  const latestSetupActivity = useSelector(getLatestSetupActivity);
  const { expectedSetupDuration } = latestActivitySet;
  const expectedSetupDurationMs = getUnitDurationFromString(
    expectedSetupDuration,
    'milliseconds'
  );
  const { expectedSetupEndDate } = useSelector(getAdjustedSetupDurationDates);
  const isPaused = useSelector(getIsPaused);
  if (isPaused) {
    return (
      <PausedWheelContent expectedSetupDurationMs={expectedSetupDurationMs} />
    );
  }

  return (
    <>
      {!!expectedSetupDurationMs && (
        <>
          <WheelLabel>
            <DurationTicker
              reverse={!isOvertime}
              startDate={expectedSetupEndDate}
              endDateOverride={isPaused ? latestSetupActivity.end : null}
              {...setupFormat}
            />
          </WheelLabel>
          <WheelSublabel>
            {_startCase(t(isOvertime ? 'overtime' : 'remaining'))}
          </WheelSublabel>
        </>
      )}
      <OpRunOverrideRejectLabel
        expectedSetupDurationMs={expectedSetupDurationMs}
      />
    </>
  );
};
