import styled from 'styled-components';
import { palette, Card } from '@m12s/component-library';
import { zLayer } from 'lib/utils/styled';
import { DefaultCardBorder } from 'components/MachineHeader/styled';
import { H5 } from 'components/Text';

const InputWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: ${({ hidden }) => {
    return hidden ? 'none' : 'block';
  }};
`;

const Root = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${palette.Grey100};
  position: relative;
  z-index: ${zLayer.overlay};
`;

const GridWrapper = styled.div`
  padding: 2.5rem 2.5rem 0;
  overflow-y: scroll;
  flex-grow: 1;
`;

const CardGridItem = styled(Card)`
  ${DefaultCardBorder}
`;

const HeaderText = styled(H5)`
  width: 100%;
  text-align: left;
  padding-left: 1.5rem;
  font-weight: 500;
  margin-left: -8rem;
`;

export { Root, GridWrapper, InputWrapper, CardGridItem, HeaderText };
