import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _startCase from 'lodash/startCase';
import { StatTab } from '@m12s/component-library';
import { useSelector } from 'react-redux';
import { DurationTicker } from 'components/DurationTicker';
import { ago, formatDuration } from 'lib/utils/date';
import { checkIsLatestShiftActive } from 'lib/selectors/checkIsLatestShiftActive';
import { getTotalShiftDowntime } from 'lib/selectors/getTotalShiftDowntime';
import { getTotalShopDayDowntime } from 'lib/selectors/getTotalShopDayDowntime';
import { getIsDown } from 'lib/selectors/getIsDown';
import { getScopeStart } from 'lib/selectors/getScopeStart';
import moment from 'moment';
import { TextMedia } from '../styled';
import { DURATION_FORMAT, DURATION_OPTIONS } from './constants';

export const DowntimeTab = () => {
  const { t } = useTranslation();
  const isLatestShiftActive = useSelector(checkIsLatestShiftActive);
  const totalShiftDowntime = useSelector(getTotalShiftDowntime);
  const totalShopDayDowntime = useSelector(getTotalShopDayDowntime);
  const totalContextualDowntime = isLatestShiftActive
    ? totalShiftDowntime
    : totalShopDayDowntime;
  const scopeStart = useSelector(getScopeStart);
  const isDown = useSelector(getIsDown);

  const allDowntimeStart = useMemo(() => {
    return ago(totalContextualDowntime, 'seconds');
  }, [totalContextualDowntime]);

  const scopeStartMoment = useMemo(() => {
    return scopeStart && moment(scopeStart);
  }, [scopeStart]);

  const scopeTotalContextualDowntime = useMemo(() => {
    if (scopeStartMoment && totalContextualDowntime) {
      const scopeStartDiffSec = moment().diff(scopeStartMoment, 'seconds');
      return scopeStartDiffSec < totalContextualDowntime
        ? scopeStartDiffSec
        : totalContextualDowntime;
    }

    return totalContextualDowntime;
  }, [totalContextualDowntime, scopeStartMoment]);

  const scopeAllDowntimeStart = useMemo(() => {
    if (scopeStartMoment && allDowntimeStart) {
      return scopeStartMoment.isAfter(allDowntimeStart)
        ? scopeStartMoment
        : allDowntimeStart;
    }
    return allDowntimeStart;
  }, [allDowntimeStart, scopeStartMoment]);

  return (
    <StatTab
      id="downtime-tab"
      value="downtime"
      label={
        <>
          <TextMedia max={60}>{_startCase(t('downtime'))}</TextMedia>
          <TextMedia min={60}>{_startCase(t('all downtime'))}</TextMedia>
        </>
      }
      secondaryLabel={
        isDown ? (
          <DurationTicker
            startDate={scopeAllDowntimeStart}
            format={DURATION_FORMAT}
            formatOptions={DURATION_OPTIONS}
          />
        ) : scopeTotalContextualDowntime ? (
          formatDuration(
            scopeTotalContextualDowntime,
            DURATION_FORMAT,
            DURATION_OPTIONS
          )
        ) : (
          '-'
        )
      }
    />
  );
};
