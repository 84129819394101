// React and Hooks
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// Actions
// Selectors
import { getMachine } from 'lib/selectors/getMachine';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
// Utils
import Routes from 'lib/utils/routes';
// Components

/**
 * Custom hook to generate the redirect path for a work order.
 *
 * @param {Object} [opts={}] - Options for generating the path.
 * @param {boolean} [opts.excludeRefFromPath] - Whether to exclude the work order operation reference from the path.
 * @returns {string} The generated redirect path.
 */
const useGenerateWorkOrderRedirectPath = (opts = {}) => {
  const excludeRefFromPath = opts?.excludeRefFromPath;
  const machine = useSelector(getMachine);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const workOrderOperationRef = currentWorkOrderOp?.workOrderOperationRef;

  const basePath = `${Routes.machineIdSelectJobPath(machine?.id)}`;
  const queryStrings = `?excludeWorkOrderOperationRefs=${encodeURIComponent([
    workOrderOperationRef,
  ])}`;

  return useMemo(() => {
    return excludeRefFromPath ? `${basePath}${queryStrings}` : basePath;
  }, [basePath, excludeRefFromPath, queryStrings]);
};

export default useGenerateWorkOrderRedirectPath;
